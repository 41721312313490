@tailwind base;
@tailwind components;
@tailwind utilities;

/* local fonts */
@import url('styles/fonts.css');

p {
  @apply text-sm md:text-base;
}

.input {
  @apply !border-border-color !bg-white;
}

.partner-img {
  @apply inline-block h-[67px];
}

.partner-year {
  @apply text-white font-bold text-lg md:text-xl text-center mb-2;
}

.partner-companies {
  @apply text-white text-center text-sm md:text-base 2xl:text-xl font-normal;
}

.contact-form-wrapper {
  @apply shadow-card bg-white py-10 px-12;
}
